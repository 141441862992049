import React from 'react';
import {useParams} from 'react-router-dom';

import './style.css';

import GiveawayView from '../../components/giveawayView';

function Page() {
    const { id } = useParams();
    
    return (
        <GiveawayView viewType={'normal'} giveawayData={{
            Title: 'ROCKET LEAGUE GIVEAWAY',
            DrawDate: '2020-10-27',
            Actions: [{
                Tipo: 'Discord_Join',
                Texto: 'Entre no Discord',
                Pontos: 1,
                IsOpcional: false
            }, {
                Tipo: 'Twitch_Watch',
                Texto: 'Siga o Masterbooyy na livestream',
                Pontos: 1,
                IsOpcional: false
            },{
                Tipo: 'Instagram',
                Texto: 'Siga-nos no Instagram',
                Pontos: 3,
                IsOpcional: true
            }]
        }} />
    );
}

export default Page;