import React from 'react';
import MetaTags from 'react-meta-tags';
import Particles from 'react-particles-js';

import './style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faInstagram } from '@fortawesome/free-brands-svg-icons';

import Logo from '../../assets/logo.svg';
import ParticleConfig from '../../assets/particles/comingsoon.json';

export default function Page() {
    return (
        <div className="container">
            <MetaTags>
                <title>Manutenção</title>
                <meta name="description" content="Best gaming community from portugal!" />
                <meta property="og:title" content="Manutenção" />
                <meta property="og:description" content="Best gaming community from portugal!" />
            </MetaTags>
            <Particles className="particles" params={ParticleConfig}/>
            <div className="panel_top">
                <img className="logo_community" src={Logo} alt="Elemental Wolves Logo"/>
                <h1 className="name_community">Elemental Wolves</h1>
                <p className="text_1">O site neste momento encontra-se em manutenção.</p>
            </div>
            <div className="panel_bottom">
                <div className="panel_bottom_top">
                    <p>Não te esqueças de nos seguir no Discord e Instagram!</p>
                </div>
                <div className="panel_bottom_bottom">
                    <div>
                        <a href="https://discord.gg/4NnG6Xh" className="social_icon" target="blank">
                            <FontAwesomeIcon icon={faDiscord}/>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.instagram.com/elemental.wolves" className="social_icon" target="blank">
                            <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}