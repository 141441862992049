import React, {useState, memo} from 'react';
import Countdown from 'react-countdown';
import ReactTooltip from 'react-tooltip';
import { useForm } from 'react-hook-form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faTwitch, faInstagram, faGoogle } from '@fortawesome/free-brands-svg-icons';

import './style.css';

import Logo from '../../assets/logo.svg';
import LogoBack from '../../assets/rocket_league_background.jpg';

const coutdownRender = ({ days, hours, minutes, seconds, completed }) => {
    if (days > 0) {
        if (days > 1) return <span>{days} dias</span>;
        else return <span>{days} dia</span>;
    } else { 
        return <span>
            {hours < 10 ? `0${hours}` : hours}:
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
            </span>;
    }
};

// viewType { normal, minimal }
function GiveawayView({ viewType='normal', giveawayData=null, giveawayId=-1 }) {
    const [loged, setloged] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [blocked, setBlocked] = useState(false);

    const { register, handleSubmit } = useForm()
    const onSubmit = data => console.log(data)

    if (giveawayData == null && giveawayId != -1) {
        // Buscar a data do giveaway apartir do giveawayId.
    }

    giveawayData.ImageURL = LogoBack;

    return viewType == 'normal' ? (
        <div className="panel_container"
            style={{ background: `url(${giveawayData.ImageURL})` }}
        >
            <ReactTooltip place="left" effect="solid" />
            <img className="panel_logo" src={Logo} alt="Elemental Wolves Logo"/>
        
            <div className="panel">
                <div className="panel_stats">
                    <div className="panel_stats_item">
                        <label>Entradas Totais</label>
                        <label className="value">0</label>
                    </div>
                    <div className="panel_stats_item">
                        <label>Tempo Restante</label>
                        <label 
                            data-place="bottom" 
                            data-tip={`Este giveaway termina dia ${new Date(giveawayData.DrawDate).toLocaleDateString('pt-PT')}`} 
                            className="value"
                        >
                            <Countdown date={giveawayData.DrawDate} renderer={coutdownRender}/>
                        </label>
                    </div>
                    <div className="panel_stats_item">
                        <label>As tuas Entradas</label>
                        <label className="value">0</label>
                    </div>
                </div>
                
                <div className="panel_titulo">
                    <label>{giveawayData.Title}</label>
                </div>
                
                <div className="panel_descricao">
                    <p>
                        Neste giveaway iremos dar uma chave para a steam do Rocket League.
                        <br/><br/>
                        Não te esquecas de comprir as regras.
                    </p>
                </div>

                { openRegister ? (
                <div className="panel_registe">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="registe_email_input">Coloque o seu Email</label>
                        <input id="registe_email_input" 
                            placeholder="exemplo@..."
                            name="email" 
                            type="email" 
                            ref={register({ required: true })} 
                            required 
                        />
                        <label htmlFor="registe_name_input">Coloque o seu Nome</label>
                        <input id="registe_name_input" 
                            placeholder="Pelo menos o primeiro e ultimo"
                            name="name" 
                            type="text" 
                            ref={register({ required: true })} 
                            required 
                        />
                        <input type="submit" value="Entrar"/>
                    </form>
                </div>
                ) : (<>
                <div className="panel_login_info">
                    <label>Está logado como <strong>BaixoGamer</strong></label>
                </div>
                
                <div className="panel_items_list">
                    {giveawayData.Actions.map(item => (
                        <div className="item">
                            <div className="item_div_img">
                                {
                                item.Tipo == 'Discord_Join' ? <FontAwesomeIcon icon={faDiscord}/> :
                                item.Tipo == 'Twitch_Watch' ? <FontAwesomeIcon icon={faTwitch}/> :
                                item.Tipo == 'Instagram' ? <FontAwesomeIcon icon={faInstagram}/> : null
                                }
                            </div>
                            <div className="item_div_title">
                                {item.IsOpcional == true ? <p className="optional">Opcional</p> : null }
                                <p className="titulo">{item.Texto}</p>
                            </div>
                            <div className="item_div_button">
                                <button data-tip="Clica para receber">+{item.Pontos}</button>
                            </div>
                        </div>
                    ))}
                </div>
                </>)}

                <div className="panel_footer">
                    {blocked ? (<>
                        <FontAwesomeIcon className="panel_footer_icon_on" icon={faLockOpen}/>
                        <p>Agora já está a participar!</p>
                    </>) : (<>
                        <FontAwesomeIcon className="panel_footer_icon_off" icon={faExclamationCircle}/>
                        <p>Faça todas as ações para entrar no Giveaway.</p>
                    </>)}
                </div>
            </div>
            <a>Powered by Elemental Wolves (BaixoGamer)</a>
        </div>
	) : ( // 
        <h1>OLA</h1>
    );
}

export default memo(GiveawayView);