import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import QueryString from 'query-string';

import Error404 from './pages/error404';

import Comingsoon from './pages/comingsoon';
import Giveaway from './pages/giveaways';

export default function Routes({ location }) {
    const querys = QueryString.parse(location.search);
    const [dev, setDev] = useState(true);
    var { devkey } = querys;
    
    // Fazer a verificação do dev.

    // Fazer a verificação do devkey.
    if (dev && devkey == undefined) {
        devkey = localStorage.getItem('devkey');
    } else if (dev) { localStorage.setItem('devkey', devkey); }
    
    const showAll = (dev ? (devkey == "2020" ? true : false) : false); 

    return (<>
        {showAll ?(
            <Switch>
                <Route path='/' exact component={() => <h1>ola</h1>}/>
                <Route path='/giveaway/:id?' component={Giveaway} />
                <Route component={Error404} />
            </Switch>
        ):(
            <Route component={Comingsoon} />
        )}
    </>);
}